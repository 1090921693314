import { useState, Fragment, lazy } from 'react';
import { Row, Col, Drawer } from 'antd';
import { CSSTransition } from 'react-transition-group';
import { withTranslation } from 'react-i18next';

import * as S from './styles';

const PngIcon = lazy(() => import('../../common/PngIcon'));
const Button = lazy(() => import('../../common/Button'));

const Header = ({ t }) => {
  const [isNavVisible] = useState(false);
  const [isSmallScreen] = useState(false);
  const [visible, setVisibility] = useState(false);

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    return (
      <Fragment>
        <S.CustomNavLinkSmall>
          <S.Span><a href="/about">{t('About')}</a></S.Span>
        </S.CustomNavLinkSmall>
        <S.CustomNavLinkSmall>
          <S.Span><a href="/pricing">{t('Pricing')}</a></S.Span>
        </S.CustomNavLinkSmall>
        {/* <S.CustomNavLinkSmall>
          <S.Span><a href="/contact">{t('Contact')}</a></S.Span>
        </S.CustomNavLinkSmall> */}
        <S.CustomNavLinkSmall style={{ width: '180px' }} >
          <S.Span>
            <a href="/contact">
              <Button>{t('Contact')}</Button>
            </a>
          </S.Span>
        </S.CustomNavLinkSmall>
        {/* <S.CustomNavLinkSmall
          style={{ width: '180px' }}
        >
          <S.Span>
            <a href="https://slack.com/oauth/v2/authorize?client_id=1581531560274.1587700048804&scope=chat:write,commands,dnd:read,team:read,users:read,users:read.email,users:write,users.profile:read&user_scope=users:write,users.profile:write">
              <Button>{t('Get Started')}</Button>
            </a>
          </S.Span>
        </S.CustomNavLinkSmall> */}
      </Fragment>
    );
  };

  return (
    <S.Header>
      <S.Container>
        <Row type="flex" justify="space-between" gutter={20}>
          <S.LogoContainer to="/" aria-label="homepage">
            <PngIcon src="logo.png" width="230px" height="76px" />
          </S.LogoContainer>
          <S.NotHidden>
            <MenuItem />
          </S.NotHidden>
          <S.Burger onClick={showDrawer}>
            <S.Outline />
          </S.Burger>
        </Row>
        <CSSTransition
          in={!isSmallScreen || isNavVisible}
          timeout={350}
          classNames="NavAnimation"
          unmountOnExit
        >
          <Drawer closable={false} visible={visible} onClose={onClose}>
            <Col style={{ marginBottom: '2.5rem' }}>
              <S.Label onClick={onClose}>
                <Col span={12}>
                  <S.Menu>Menu</S.Menu>
                </Col>
                <Col span={12}>
                  <S.Outline padding="true" />
                </Col>
              </S.Label>
            </Col>
            <MenuItem />
          </Drawer>
        </CSSTransition>
      </S.Container>
    </S.Header>
  );
};

export default withTranslation()(Header);
